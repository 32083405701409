/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import {
  HiOutlineUsers,
  HiOutlineTag,
  HiOutlineUserGroup,
  HiOutlineBellAlert,
  HiOutlineUserPlus,
  HiOutlineLightBulb,
  HiOutlineHandThumbUp
} from 'react-icons/hi2';
import { RiDashboardLine } from 'react-icons/ri';
import { RiExchangeDollarFill } from 'react-icons/ri';
import { BsTags, BsSpeedometer2, BsFillCheckCircleFill, BsInfoCircleFill } from 'react-icons/bs';
import { FaSearchDollar } from 'react-icons/fa';
import { TbAppWindow } from 'react-icons/tb';
import { MdWarning, MdOutlineManageAccounts } from 'react-icons/md';
import { ImTree } from 'react-icons/im';
import { VscError } from 'react-icons/vsc';
import { SiDatabricks } from 'react-icons/si';
import { GiSpeedometer } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';
import { AiOutlineCloudServer, AiOutlineAppstore, AiOutlineAudit } from 'react-icons/ai';
import { TbReportAnalytics } from 'react-icons/tb';
import { Toast } from 'primereact/toast';
import _ from 'lodash';
import '../assets/css/layout/layout.css';
import NavBar from '../components/common/navBar';
import SideBar from '../components/common/sideBar';
import CloudAi from '../features/cloudAi/CloudAi';
import { TbTags, TbTableOptions, TbTrendingUp } from 'react-icons/tb';
import { RxLayers, RxDisc } from 'react-icons/rx';
import { useSelector, useDispatch } from 'react-redux';
import { closeToast } from '../features/base/baseSlice';
import { getRandomNumber } from '../helpers/helper';
import { IoBarChartOutline } from 'react-icons/io5';
import { IoMdAnalytics } from 'react-icons/io';
import chat_gpt from '../assets/icons/chat_gpt.png';

const renderNavIcon = (key, isAdditional) => {
  switch (key) {
    case 'Dashboard':
      return <RiDashboardLine className="h-10 text-2xl nav_icon transition-colors duration-300" />;
    case 'FinOps Health':
      return (
        <TbTableOptions className="h-8 w-8 text-2xl nav_icon transition-colors duration-300" />
      );
    case 'cost-analysis':
      return (
        <AiOutlineCloudServer className="h-8 w-10 text-2xl nav_icon transition-colors duration-300 cursor-pointer" />
      );
    case 'Voya Solutions':
      return (
        <TbReportAnalytics className="h-8 w-8 text-2xl nav_icon transition-colors duration-300" />
      );
    case 'by Resource Group':
      return <ImTree className="h-8 w-9 pl-4 text-xl nav_icon transition-colors duration-300" />;
    case 'by Meter Category':
      return (
        <BsSpeedometer2 className="h-8 w-10 pl-4  text-xl nav_icon transition-colors duration-300" />
      );
    case 'Meter Growth':
      return <GiSpeedometer className="h-8 w-10 pl-4 text-xl  transition-colors duration-300" />;
    case 'tag-governance':
      return (
        <BsTags className="h-8 w-8 text-2xl nav_icon transition-colors duration-300 cursor-pointer" />
      );

    case 'Monthly Tag Maturity':
      return <HiOutlineTag className="h-8 w-8 text-2xl nav_icon transition-colors duration-300" />;
    case 'Individual Tags':
      return (
        <TbTags className="h-8 w-12 pl-4  text-2xl nav_icon transition-colors duration-300 " />
      );
    case 'Users':
      return (
        <HiOutlineUserPlus className="h-8 w-10 text-2xl nav_icon transition-colors duration-300" />
      );
    case 'Admin Center':
      return (
        <HiOutlineUsers className="h-8 w-10 text-2xl nav_icon transition-colors duration-300" />
      );
    case 'Custom Reports':
      return <RxLayers className="h-8 w-8 text-2xl nav_icon transition-colors duration-300" />;
    case 'Anomaly Insights':
      return <RxDisc className="h-8 w-10 pl-4 text-xl  transition-colors duration-300" />;
    case 'Alerts':
      return (
        <HiOutlineBellAlert className="h-8 w-8 text-2xl nav_icon transition-colors duration-300 cursor-pointer" />
      );
    case 'Build Your Own':
      return <TbAppWindow className="h-8 w-8 text-xl  transition-colors duration-300" />;
    case 'build-your-own':
      return (
        <AiOutlineAppstore className="h-8 w-8 text-2xl nav_icon transition-colors duration-300 cursor-pointer" />
      );
    case 'admin-center':
      return (
        <HiOutlineUsers className="h-8 w-8 text-2xl nav_icon transition-colors duration-300" />
      );
    case 'Groups':
      return (
        <HiOutlineUserGroup className="h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300" />
      );
    case 'Grouped Applications':
      return <TbAppWindow className="h-8 w-10 pl-4 text-xl  transition-colors duration-300" />;
    case 'Module Access':
      return (
        <MdOutlineManageAccounts className="h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300" />
      );
    case 'PaaS-Analytics':
      return <IoMdAnalytics className="h-8 w-8 text-2xl nav_icon transition-colors duration-300" />;
    case 'Azure Databricks':
      return <SiDatabricks className="h-8 w-8 text-2xl nav_icon transition-colors duration-300" />;
    case 'Forecast Model':
      return (
        <IoBarChartOutline className="h-8 w-8 text-2xl nav_icon transition-colors duration-300" />
      );
    case 'suggestion-engine':
      return (
        <HiOutlineLightBulb className="h-7 w-7 text-2xl nav_icon transition-colors duration-300 " />
      );
    case 'Anomaly Detection':
      return (
        <FaSearchDollar className="h-8 w-8 text-2xl nav_icon transition-colors duration-300" />
      );
    case 'Recommendations':
      return (
        <HiOutlineHandThumbUp className="h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300" />
      );
    case 'Tag Overview':
      return (
        <AiOutlineAudit className="h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300" />
      );
    case 'Trend Analysis':
      return (
        <TbTrendingUp className="h-8 w-10 pl-4 text-2xl nav_icon transition-colors duration-300" />
      );
    case 'ROI Review':
      return (
        <RiExchangeDollarFill className="h-8 w-10 text-2xl nav_icon transition-colors duration-300" />
      );
    default:
      return (
        <RiDashboardLine
          className={
            isAdditional
              ? 'h-8 w-8 text-2xl nav_icon transition-colors duration-300'
              : 'h-10 text-2xl nav_icon transition-colors duration-300'
          }
        />
      );
  }
};

function Layout({ children }) {
  const toastRef = useRef();
  const [isMobileView, setIsMobileView] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const {
    toast: { flag, message, heading, mode = 'success', life },
    permission
  } = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const renderIcon = (type) => {
    switch (type) {
      case 'success':
        return <BsFillCheckCircleFill className="w-[2.5rem] h-[2.5rem] text-green-500" />;
      case 'info':
        return <BsInfoCircleFill className="w-[2.5rem] h-[2.5rem] text-secondary" />;
      case 'warn':
        return <MdWarning className="w-[2.5rem] h-[2.5rem] text-yellow-500" />;
      case 'error':
        return <VscError className="w-[2.5rem] h-[2.5rem] text-red-500" />;
      default:
        return <BsFillCheckCircleFill className="w-[2.5rem] h-[2.5rem] text-green-500" />;
    }
  };
  const handleHamburgerClick = () => {
    setIsMobileView(!isMobileView);
  };

  const handleOpenChat = () => {
    setShowChat(true);
  };

  const onHideChat = () => {
    setShowChat(false);
  };

  const getNavigator = () => {
    let navigator = [];
    if (permission && Array.isArray(permission)) {
      const groupData = _.groupBy(permission, 'category');
      for (const category in groupData) {
        if (Object.hasOwnProperty.call(groupData, category)) {
          const groupedNav = groupData[category];
          if (groupedNav.length > 0) {
            const isExpand = groupedNav.length > 1;
            const nav = {
              id: getRandomNumber()
            };
            if (isExpand) {
              nav.label = category;
              nav.expandMode = true;
              nav.title = category;
              nav.icon = renderNavIcon(category);
              nav.additionalRoute = [];
              for (const current_nav of groupedNav) {
                if (
                  !current_nav.permissions ||
                  (current_nav.permissions &&
                    current_nav.permissions &&
                    Boolean(current_nav.permissions.read))
                ) {
                  nav.additionalRoute.push({
                    label: current_nav.label,
                    icon: renderNavIcon(current_nav.label, true),
                    path: `/${current_nav.link}`,
                    id: 1
                  });
                }
              }
            } else {
              const current_nav = groupedNav[0];
              if (
                !current_nav.permissions ||
                (current_nav.permissions &&
                  current_nav.permissions &&
                  Boolean(current_nav.permissions.read))
              ) {
                nav.path = `/${current_nav.link}`;
                nav.label = current_nav.label;
                nav.icon = renderNavIcon(current_nav.label);
              }
            }
            navigator.push(nav);
          }
        }
      }
    }
    return navigator;
  };

  useEffect(() => {
    if (flag) {
      toastRef.current.show({
        severity: mode,
        summary: heading,
        detail: message,
        life,
        closeIcon: <GrClose />,
        icon: renderIcon(mode)
      });
      setTimeout(() => {
        dispatch(closeToast());
      }, 3000);
    }
  }, [flag]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 765);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigator_data = getNavigator();

  return (
    <main className="layout_container overflow-hidden h-screen">
      <Toast ref={toastRef} position="bottom-right" />
      <NavBar handleHamburgerClick={handleHamburgerClick} />
      <section className="flex relative ">
        <div className="flex flex-row">
          <SideBar sideNavs={navigator_data} isMobileView={isMobileView} />
        </div>
        <section className="w-full layout_content">
          <section className="p-4 bg-white overflow-auto h-calc-layout">{children}</section>
          {showChat ? (
            <CloudAi handleCloseChat={onHideChat} />
          ) : (
            <div className="container-ping" onClick={handleOpenChat}>
              <div className="ping w-14 h-14">
                <img className="w-10 h-10 bg-white rounded-full" src={chat_gpt} />
              </div>
            </div>
          )}
        </section>
      </section>
    </main>
  );
}

const mapStateToProps = (state) => {
  return {
    toast: state.base.toast,
    permission: state.base.permission
  };
};

export default Layout;
