import { Outlet, Navigate } from 'react-router-dom';
import { getLocalStroageData } from '../helpers/LocalHelper';
import localConstant from '../constant/localConstant';
import constant from '../constant';

function PrivateRoute() {
  const token = getLocalStroageData(localConstant.TOKEN);
  const permit = getLocalStroageData(localConstant.PERMIT);
  return token && permit === 'true' ? <Outlet /> : <Navigate to={constant.home_path} />;
}

export default PrivateRoute;
