const dotWaveAnimation = () => {
  return (
    <div id="wave">
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
    </div>
  );
};

const dotBounceAnimation = () => {
  return (
    <div className="spinner">
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
};

export { dotWaveAnimation, dotBounceAnimation };
