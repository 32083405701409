/* eslint import/no-cycle: "off" */
import api from '../../app/api';
import endPoint from '../../constant/endPoints';

const accountQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: (body) => ({
        url: endPoint.login,
        method: 'POST',
        body
      }),
      transformResponse: (responseData) => responseData.data
    }),
    getUserData: builder.query({
      query: () => '/'
    }),
    userLogout: builder.mutation({
      query: () => ({
        url: endPoint.logout,
        method: 'POST',
        body: {}
      })
    }),
    getUserDetail: builder.mutation({
      query: () => ({
        url: endPoint.userDetail,
        method: 'POST',
        body: {}
      }),
      transformResponse: (resp) => (resp && resp.data ? resp.data.user : {})
    }),
    getUserPermission: builder.query({
      query: () => endPoint.permission,
      transformResponse: (response) =>
        response && response.data && response.data.permissions ? response.data.permissions : []
    }),
    orgOverView: builder.query({
      query: () => {
        return {
          url: endPoint.orgOverView,
          method: 'GET'
        };
      },
      transformResponse: (resp) => (resp && resp.data ? resp.data : {})
    }),
    dataImportedAt: builder.query({
      query: () => {
        return {
          url: endPoint.getDataImportedDate,
          method: 'GET'
        };
      },
      transformResponse: (resp) => resp
    })
  })
});

export const {
  useUserLoginMutation,
  useGetUserDataQuery,
  useUserLogoutMutation,
  useGetUserDetailMutation,
  useLazyGetUserPermissionQuery,
  useLazyOrgOverViewQuery,
  useLazyDataImportedAtQuery
} = accountQuery;
