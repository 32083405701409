/* eslint no-console: "off" */
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Suspense, useLayoutEffect, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import constant from '../constant';
import PageNotFound from '../features/pageNotFound/pageNotFound';
import Layout from '../containers/layout';
import PrivateRoute from '../utils/PrivateRoute';
import SignIn from '../features/account/signIn';
import Spinner from '../components/common/spinner';
import { updateUserPermission } from '../features/base/baseSlice';
import appComponents from './routes';
import {
  useLazyGetUserPermissionQuery
  // useUserLogoutMutation
} from '../features/account/accountSlice';
import { clearLocal, getLocalStroageData } from '../helpers/LocalHelper';
import localConstant from '../constant/localConstant';
import ReportDownloader from '../features/reportDownloader/reportDownloader';

function AppRouter() {
  const token = getLocalStroageData(localConstant.TOKEN);
  const [getPermission] = useLazyGetUserPermissionQuery();
  const permission = useSelector((state) => state.base.permission);
  const dispatch = useDispatch();

  const updatePermissionState = () => {
    getPermission()
      .unwrap()
      .then((response) => {
        let moduleAccess = {};
        let groups = {};
        response.forEach((e) => {
          if (e.link.includes('admin-center') && !e.link.includes('users')) {
            if (e.link.includes('module-access')) {
              moduleAccess = e.permissions;
            } else if (e.link.includes('groups')) {
              groups = e.permissions;
            }
          }
        });
        const array = response
          .map((e) => {
            const obj = { ...e };
            let holder;
            if (e.link.includes('users') && e.link.includes('admin-center')) {
              obj.link = 'admin-center';
              holder = { ...obj, moduleAccess, groups };
            } else if (!e.link.includes('admin-center') && !e.link.includes('users')) {
              holder = obj;
            }
            return holder;
          })
          .filter((item) => item !== undefined);

        dispatch(updateUserPermission(array));
      })
      .catch((error) => {
        console.log(error);
        clearLocal();
        window.location.href = '/';
      });
  };

  const isTokenValid = () => {
    try {
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          // Token verification failed
          clearLocal();
          return false;
        }
        // Token is valid
        return true;
      }
      clearLocal();
      return false;
    } catch (error) {
      // Token verification failed
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    // Check if the token is not null before calling isTokenValid
    const userPath = window.location.pathname !== '/';
    isTokenValid();
    if (!token && userPath) {
      window.location.href = constant.home_path;
    }
  }, [token]);

  useLayoutEffect(() => {
    if (!permission && token) {
      updatePermissionState();
    }
  }, [permission]);

  return (
    <Suspense fallback={<Spinner />}>
      <Router>
        <Routes>
          <Route path={constant.home_path} element={<SignIn />} />
          <Route element={<PrivateRoute />}>
            {permission &&
              permission.map((route, routerIndex) => {
                const { link: path, label: screen } = route;
                const uniqueKey = `${screen}-${routerIndex}`;
                const routePermission = {
                  label: screen,
                  permission:
                    route.permissions && Array.isArray(route.permissions)
                      ? route.permissions[0]
                      : route.permissions,
                  moduleAccess: route?.moduleAccess,
                  groups: route?.groups
                };
                const component = appComponents(screen, routePermission);
                if (routePermission.permission && !Boolean(routePermission.permission.read)) {
                  return null;
                }
                return <Route element={<Layout>{component}</Layout>} path={path} key={uniqueKey} />;
              })}
          </Route>
          <Route path="/report" element={<ReportDownloader />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default AppRouter;
