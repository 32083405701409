const localConstant = {
  TOKEN: 'TOKEN',
  USER: 'USER',
  PARAM_TOKEN_KEY: 'access_token',
  AMORT: 'AMORT',
  PERMIT: 'PERMIT',
  REFRESH: 'REFRESH',
  AI: 'AI',
  CLOUD_PARTNER: 'CLOUD_PARTNER',
  DATA_IMPORTED_AT: 'DATA_IMPORTED_AT'
};

export default localConstant;
