import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { SiMicrosoftexcel } from 'react-icons/si';

function reportDownloader() {
  return (
    <main className="w-full h-screen grid place-items-center bg-gray-200">
      <Card className="w-[300px] ">
        <div className="w-full flex flex-col items-center gap-y-3">
          <SiMicrosoftexcel className="w-[3rem] h-[3rem] text-secondary" />
          <p>File Name : Report.csv</p>
          <p>File Size : 20mb</p>
          <Button
            icon="pi pi-download"
            outlined
            aria-label="Filter"
            label="Download"
            className="!text-sm !text-secondary"
          />
        </div>
      </Card>
    </main>
  );
}

export default reportDownloader;
