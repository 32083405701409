import ReactECharts from 'echarts-for-react';
import { Skeleton } from 'primereact/skeleton';

function DonutChart({ data, loading }) {
  const colors = ['#cbd5e1', '#0F6CBD'];
  const totalPercentage = 100;

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        if (params.name === data.name) {
          return `${params.name}: ${params.value}%`;
        }
        return '';
      }
    },

    series: [
      {
        name: 'Donut Chart',
        type: 'pie',
        radius: ['42%', '60%'],
        avoidLabelOverlap: false,
        label: {
          show: false
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '20',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          {
            value: totalPercentage - data.value,
            itemStyle: {
              color: colors[0]
            }
          },
          {
            name: data.name,
            value: data.value,
            itemStyle: {
              color: data.value > 70 ? '#F94449' : colors[1]
            },
            label: {
              show: true,
              position: 'center',
              fontFamily: 'Neue Montreal, sans-serif',
              formatter: `{b|${data.value}%}\n{a|${data.name}}`,
              rich: {
                a: {
                  color: 'black',
                  fontSize: 12,
                  lineHeight: 20,
                  padding: [75, 0, 0, 0],
                  fontFamily: 'Neue Montreal, sans-serif'
                },
                b: {
                  color: 'black',
                  fontSize: 12,
                  lineHeight: 16,
                  position: 'center',
                  padding: [25, 0, 0, 0],
                  fontFamily: 'Neue Montreal, sans-serif'
                }
              }
            }
          }
        ]
      }
    ]
  };

  return (
    <div style={{ width: '100%', height: '110px', marginBottom: '3px' }}>
      {loading ? (
        <div className="w-full h-full grid place-content-center">
          <Skeleton shape="circle" width="60px" height="60px" />
        </div>
      ) : (
        <ReactECharts
          option={option}
          style={{ width: '110px', height: '105px', marginBottom: '6px' }}
        />
      )}
    </div>
  );
}

export default DonutChart;
