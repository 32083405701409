/* eslint-disable */
import moment from 'moment';

const monthYearRegex = /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;

const m = [
  '',
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

const monthMapping = {
  January: '2023-january',
  February: '2023-february',
  March: '2023-march',
  April: '2023-april',
  May: '2023-may',
  June: '2023-june',
  July: '2023-july',
  August: '2023-august',
  September: '2023-september',
  October: '2023-october',
  November: '2023-November',
  December: '2023-december'
};

const generateMonthMapping = (fromMonth, toMonth, year) => {
  const monthMapping = {};
  if (fromMonth < 1 || fromMonth > 12 || toMonth < 1 || toMonth > 12) {
    throw new Error('Invalid fromMonth or toMonth value. They should be between 1 and 12.');
  }
  for (let month = fromMonth - 1; month < toMonth; month++) {
    const monthDate = new Date(year, month, 1);
    const monthName = monthDate.toLocaleString('default', { month: 'long' });
    const key = `${year}-${monthName.toLowerCase()}`;
    monthMapping[monthName] = key;
  }

  return monthMapping;
};

function getMonthYearArray(startDate, endDate) {
  const result = [];
  const currentDate = new Date(startDate);

  const monthsDiff =
    (endDate.getFullYear() - startDate.getFullYear()) * 12 +
    (endDate.getMonth() - startDate.getMonth());

  for (let i = 0; i <= monthsDiff; ) {
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();
    result.push(`${month} ${year}`);
    currentDate.setMonth(currentDate.getMonth() + 1);
    i += 1;
  }

  return result;
}

const getPreviousMonth = () => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 2, // replaced -1 by -2 to get previous month data
    1
  ).toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
};

function getRequiredPreviousMonthDates(monthsToSubtract) {
  //month from-date and end-date
  const startDate = moment()
    .subtract(monthsToSubtract, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');
  const endDate = moment().subtract(monthsToSubtract, 'months').endOf('month').format('YYYY-MM-DD');

  return {
    'from-date': startDate,
    'to-date': endDate
  };
}

const getAdditionalMonth = (month) => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth() + month, // get additional month from the current month
    1
  ).toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
};

const getDynamicAdditionalMonth = (fromDate, month) => {
  const additionalDate = moment(fromDate).add(month, 'months').format('MMMM YYYY');
  return additionalDate;
};

const getCurrentYear = () => {
  return new Date().getFullYear();
};

const getCurrentMonth = () => {
  return new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric'
  });
};

const getLocalSet = () => {
  return false;
};
const getFromDateAndToDate = (dateStr) => {
  const [month, year] = dateStr.split(' ');
  const fromDate = new Date(`${year}-${month}-01`);
  const toDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);
  const dateFormat = {
    from: fromDate,
    to: toDate
  };
  return dateFormat;
};

const monthShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];
const minMonthShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

// Create the chart label like Jan '23
const formattedDates = (data) => {
  return data?.map((item) => {
    const monthIndex = parseInt(item.month) - 1; // Adjust for zero-based indexing
    const year = item.year.slice(-2); // Extract the last two characters of the year
    return `${minMonthShort[monthIndex]}'${year}`;
  });
};
// Create the chart label like Jan. Feb, Mar
const formattedIndexArr = (data) => {
  return data?.map((item) => {
    const monthIndex = parseInt(item.month) - 1; // Adjust for zero-based indexing
    const year = item.year.slice(-2); // Extract the last two characters of the year
    return `${minMonthShort[monthIndex]}`;
  });
};

const getInitialMonthFilter = () => {
  const n = 5;
  var months = [];
  var today = new Date();
  var year = today.getFullYear();
  var month = today.getMonth();
  const monthObj = {};
  var i = 0;
  do {
    months.push(year + '-' + m[parseInt((month > 9 ? '' : '0') + month)]);
    if (month === 1) {
      month = 12;
      year--;
    } else {
      month--;
    }
    i++;
  } while (i < n);
  months = [...months, ...new Set(months.map((month) => month.split('-')[0]))];
  for (const key of months) {
    if (key.includes('-')) {
      monthObj[key] = {
        checked: true,
        partialChecked: false
      };
    } else {
      monthObj[key] = {
        checked: false,
        partialChecked: true
      };
    }
  }
  return monthObj;
};

const getInitialMonthFilterForDashBoard = () => {
  const n = 1;
  const skipMonths = 0;
  var months = [];
  var monthNames = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ];
  var today = new Date();
  var year = today.getFullYear();
  var month = today.getMonth();
  const monthObj = {};
  var i = 0;

  if (skipMonths >= 0) {
    month -= skipMonths; // Skip the specified number of months
    while (month < 0) {
      month += 12;
      year--;
    }
  }

  do {
    const formattedMonth = year + '-' + (month > 9 ? '' : '0') + month;
    months.push(year + '-' + monthNames[month - 1]); // Subtracting 1 because the array index starts from 0
    if (month === 1) {
      month = 12;
      year--;
    } else {
      month--;
    }
    i++;
  } while (i < n);

  months = [...months, ...new Set(months.map((month) => month.split('-')[0]))];

  for (const key of months) {
    if (key.includes('-')) {
      monthObj[key] = {
        checked: true,
        partialChecked: false
      };
    } else {
      monthObj[key] = {
        checked: false,
        partialChecked: true
      };
    }
  }
  return monthObj;
};

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return `${date.getFullYear()}-${
    date.getMonth() + 1 <= 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }-${date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()}`;
};

const getCurrentMonthDateRange = () => {
  const currentDate = new Date();
  const currentMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const currentMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  return {
    startDate: formatDate(currentMonthStart),
    endDate: formatDate(currentMonthEnd)
  };
};

const getTillDateRange = () => {
  return {
    'from-date': '2020-01-01',
    'to-date': formatDate(new Date())
  };
};

const getTillRequiredDateRange = (range) => {
  const monthRange = getRequiredStartToEndMonth(range);
  return {
    'from-date': formatDate(monthRange[0]),
    'to-date': formatDate(monthRange[1])
  };
};

const getTillDateRangeIsoFormat = () => {
  const startDate = moment().subtract(4, 'months').startOf('month');
  const endDate = moment().subtract(1, 'months').endOf('month');

  return {
    'from-date': startDate.format('YYYY-MM-DD'),
    'to-date': endDate.format('YYYY-MM-DD')
  };
};

function formatIsoDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

const getRequiredIsoFormat = (data) => {
  return {
    'from-date': formatIsoDate(data.value[0]),
    'to-date': formatIsoDate(data.value[1])
  };
};

const CurrentYearTillDateRange = () => {
  return {
    'from-date': moment().startOf('year').format('YYYY-MM-DD'),
    'to-date': moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
  };
};

const getPreviousMonthDateRange = () => {
  const currentDate = new Date(); // Current date
  const lastMonthEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  const lastMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

  const formattedStartDate = formatDate(lastMonthStartDate);
  const formattedEndDate = formatDate(lastMonthEndDate);
  const updatedDateParams = {
    'from-date': formattedStartDate,
    'to-date': formattedEndDate
  };
  return updatedDateParams;
};

const getCustomRangeInitial = () => {
  const from = '2023/06/01';
  const to = '2023/06/30';
  return {
    startDate: formatDate(new Date(from)),
    endDate: formatDate(new Date(to))
  };
};

const getCurrentWeekDateRange = () => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  const currentWeekStart = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - currentDay
  );
  const currentWeekEnd = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + (6 - currentDay)
  );
  return {
    startDate: formatDate(currentWeekStart),
    endDate: formatDate(currentWeekEnd)
  };
};

const getLast3MonthsDateRange = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const last3Months = currentMonth < 3 ? currentMonth + 12 - 3 : currentMonth - 3;
  const last3MonthsYear = currentMonth < 3 ? currentYear - 1 : currentYear;
  const last3MonthsStart = new Date(last3MonthsYear, last3Months, 1);
  const lastMonthEndDate = new Date(currentYear, currentMonth, 0);
  return {
    startDate: formatDate(last3MonthsStart),
    endDate: formatDate(lastMonthEndDate)
  };
};

const getLastSixMonthDateRange = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() - 1;
  const currentYear = currentDate.getFullYear();
  const startMonth = currentMonth >= 5 ? currentMonth - 5 : currentMonth + 7;
  const endMonth = currentMonth;
  const startYear = startMonth > endMonth ? currentYear - 1 : currentYear;
  const endYear = currentYear;
  const startDate = new Date(startYear, startMonth, 1);
  const endDate = new Date(endYear, endMonth + 1, 0);
  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate)
  };
};

const getSubtractedMonthFromCurr = (startMonthOffset, endMonthOffset) => {
  // For [] timezone format
  // Subtract startMonthOffset months from current month for start //Subtract endMonthOffset months from current month for end date
  const startDate = moment().subtract(startMonthOffset, 'months').startOf('month').toString();
  const endDate = moment().subtract(endMonthOffset, 'months').endOf('month').toString();

  return [startDate, endDate];
};

const getReqMonthsFromCurr = (startMonthOffset, endMonthOffset) => {
  // For {} yyyy-mm-dd format
  // Subtract startMonthOffset months from current month for start //Subtract endMonthOffset months from current month for end date
  const startDate = moment().subtract(startMonthOffset, 'months').startOf('month').toString();
  const endDate = moment().subtract(endMonthOffset, 'months').endOf('month').toString();

  return { 'from-date': formatDate(startDate), 'to-date': formatDate(endDate) };
};

const getSubtractedMonthLabelFromCurr = (startMonthOffset, endMonthOffset) => {
  // [Nov 2023, ...]
  return Array.from({ length: startMonthOffset - endMonthOffset + 1 }, (_, index) => {
    return moment()
      .subtract(startMonthOffset - index, 'months')
      .format('MMM YYYY');
  });
};

const getRequiredFromDateObj = (
  reqStartDate,
  reqToDate,
  isNormalFormat = false,
  isByPattern = false
) => {
  const fromDate = moment(reqToDate)
    .subtract(reqStartDate, 'months')
    .startOf('month')
    .format(isNormalFormat ? 'YYYY-MM-DD' : 'ddd MMM D YYYY HH:mm:ss [GMT]ZZ');

  return {
    'from-date': fromDate,
    'to-date': reqToDate,
    ...(isByPattern && { 'request-from': 'byPatternApp' })
  };
};

const getRequiredFromDateArr = (reqStartDate, reqToDate, isNormalFormat = false) => {
  // get the from-date to subtracted by reqToDate
  const fromDate = moment(reqToDate)
    .subtract(reqStartDate, 'months')
    .startOf('month')
    .format(isNormalFormat ? 'YYYY-MM-DD' : 'ddd MMM D YYYY HH:mm:ss [GMT]ZZ');

  return [fromDate, reqToDate];
};

const getLastSevenDaysDateRange = () => {
  const currentDate = new Date();
  const lastSevenDaysStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 6
  );
  const lastSevenDaysEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  return {
    startDate: formatDate(lastSevenDaysStartDate),
    endDate: formatDate(lastSevenDaysEndDate)
  };
};

const getLast30DaysDateRange = () => {
  const today = new Date();
  const thirtyDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
  return {
    startDate: formatDate(thirtyDaysAgo),
    endDate: formatDate(today)
  };
};

const getCurrentQuarterDateRange = () => {
  const today = new Date();
  const currentQuarter = Math.floor(today.getMonth() / 3);
  const quarterStartDate = new Date(today.getFullYear(), currentQuarter * 3, 1);
  const quarterEndDate = new Date(
    quarterStartDate.getFullYear(),
    quarterStartDate.getMonth() + 3,
    0
  );
  return {
    startDate: formatDate(quarterStartDate),
    endDate: formatDate(quarterEndDate)
  };
};

const getPreviousQuarterDateRange = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentQuarter = Math.floor(currentMonth / 3);

  const prevQuarterStart = new Date(currentDate.getFullYear(), currentQuarter * 3 - 3, 1);
  const prevQuarterEnd = new Date(currentDate.getFullYear(), currentQuarter * 3, 0);

  return {
    startDate: formatDate(prevQuarterStart),
    endDate: formatDate(prevQuarterEnd)
  };
};

const getRequiredDateRange = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const rangeStart = new Date(currentYear, 4, 1);
  const rangeEnd = new Date(currentYear, currentDate.getMonth(), 0);

  return {
    startDate: formatDate(rangeStart),
    endDate: formatDate(rangeEnd)
  };
};
const getLast4RequiredDateRange = () => {
  const currentDate = new Date();
  const rangeStart = new Date(currentDate);
  rangeStart.setMonth(currentDate.getMonth() - 4);
  rangeStart.setDate(1);

  const rangeEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

  return {
    startDate: formatDate(rangeStart),
    endDate: formatDate(rangeEnd)
  };
};

const getLast12MonthsDateRange = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const last12Months = currentMonth < 11 ? currentMonth + 12 - 11 : currentMonth - 11;
  const last12MonthsYear = currentMonth < 11 ? currentYear - 1 : currentYear;
  const last12MonthsStart = new Date(last12MonthsYear, last12Months, 1);
  const lastMonthEndDate = new Date(currentYear, currentMonth, 0);
  return {
    startDate: formatDate(last12MonthsStart),
    endDate: formatDate(lastMonthEndDate)
  };
};

const defaultDateStatus = {
  startDate: '',
  endDate: ''
};

const getDateByType = (type) => {
  let dateStatus = {};
  switch (type) {
    case 'current-month':
      dateStatus = getCurrentMonthDateRange();
      break;
    case 'till-date':
      dateStatus = getTillDateRange();
      break;
    case 'previous-month':
      dateStatus = getPreviousMonthDateRange();
      break;
    case 'current-week':
      dateStatus = getCurrentWeekDateRange();
      break;
    case 'last-3months':
      dateStatus = getLast3MonthsDateRange();
      break;
    case 'last-6months':
      dateStatus = getLastSixMonthDateRange();
      break;
    case 'last-7days':
      dateStatus = getLastSevenDaysDateRange();
      break;
    case 'last-30days':
      dateStatus = getLast30DaysDateRange();
      break;
    case 'current-quarter':
      dateStatus = getCurrentQuarterDateRange();
      break;
    case 'Previous-quarter':
      dateStatus = getPreviousQuarterDateRange();
      break;
    case 'last-12months':
      dateStatus = getLast12MonthsDateRange();
      break;
    case 'custom-range':
      dateStatus = getPreviousMonthDateRange();
      break;
    default:
      dateStatus = defaultDateStatus;
  }
  return dateStatus;
};

const formatDateString = (date) => {
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'Asia/Calcutta'
  });
};

function endOfMonth(dateStr) {
  const date = new Date(dateStr);
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

function convertYearDataArray(yearDataArray) {
  const dateOptions = [];
  const yearArray = [];
  yearDataArray?.length &&
    yearDataArray?.forEach((dateString) => {
      const year = dateString.split(' ')[1];
      if (yearArray.indexOf(year) === -1) {
        yearArray.push(year);
      }
    });
  yearArray.forEach((year) => {
    const objTemplate = { key: '', label: '', data: '', children: [] };
    objTemplate.key = year;
    objTemplate.label = year;
    objTemplate.data = year;
    yearDataArray.forEach((date) => {
      const monthTemplate = { key: '', label: '', data: '' };
      const month = date.split(' ')[0];
      if (date.includes(year)) {
        monthTemplate.key = `${year}-${month.toLowerCase()}`;
        monthTemplate.label = month;
        monthTemplate.data = `${month} ${year}`;
        objTemplate.children.push(monthTemplate);
      }
    });
    dateOptions.push(objTemplate);
  });
  return dateOptions;
}
function convertYearDataArrayForDashBoard(yearDataArray) {
  const extraOptions = [
    { key: 'last-7days', label: 'Last 7 Days', data: 'last-7days', className: 'radio' },
    { key: 'last-30days', label: 'Last 30 Days', data: 'last-30days', className: 'radio' },
    { key: 'last-3months', label: 'Last 3 Months', data: 'last-3months', className: 'radio' },
    { key: 'last-6months', label: 'Last 6 Months', data: 'last-6months', className: 'radio' },
    { key: 'last-12months', label: 'Last 12 Months', data: 'last-12months', className: 'radio' },
    { key: 'current-week', label: 'Current Week', data: 'current-week', className: 'radio' },
    { key: 'current-month', label: 'Current Month', data: 'current-month', className: 'radio' },
    {
      key: 'current-quarter',
      label: 'Current Quarter',
      data: 'current-quarter',
      className: 'radio'
    },
    {
      key: 'Previous-quarter',
      label: 'Previous Quarter',
      data: 'Previous Quarter',
      className: 'radio'
    }
  ];
  const customDate = {
    key: 'Custom Date Range',
    label: 'Custom Date Range',
    data: 'Custom Date Range',
    children: [],
    selectable: false
  };
  const dateOptions = [];
  const yearArray = [];
  yearDataArray?.length &&
    yearDataArray?.forEach((dateString) => {
      const year = dateString.split(' ')[1];
      if (yearArray.indexOf(year) === -1) {
        yearArray.push(year);
      }
    });
  yearArray.forEach((year) => {
    const objTemplate = { key: '', label: '', data: '', children: [] };
    objTemplate.key = year;
    objTemplate.label = year;
    objTemplate.data = year;
    yearDataArray.forEach((date) => {
      const monthTemplate = { key: '', label: '', data: '' };
      const month = date.split(' ')[0];
      if (date.includes(year)) {
        monthTemplate.key = `${year}-${month.toLowerCase()}`;
        monthTemplate.label = `${month} ${year}`;
        monthTemplate.data = `${month} ${year}`;
        objTemplate.children.push(monthTemplate);
      }
    });
    dateOptions.push(objTemplate);
  });
  customDate.children = dateOptions;
  extraOptions.push(customDate);
  return extraOptions;
}
function getLatestMonth(data) {
  const months = data.map((entry) => new Date(entry.field + '-01'));
  const latestMonth = new Date(Math.max(...months));

  const month = latestMonth.toLocaleString('default', { month: 'long' });
  const year = latestMonth.getFullYear();

  return year + '-' + month.toLowerCase();
}
function basedOnlatestMonth(formattedResponse, latestMonth) {
  return formattedResponse.sort((a, b) => b['data'][latestMonth] - a['data'][latestMonth]);
}

function getStartAndEndDate(monthYear) {
  const startDate = moment(monthYear, 'MMMM YYYY').startOf('month').format('YYYY-MM-DD');
  const endDate = moment(monthYear, 'MMMM YYYY').endOf('month').format('YYYY-MM-DD');

  return {
    startDate,
    endDate
  };
}

const getYearList = (count = 30) => {
  return new Array(count).fill(2000).map((doc, index) => {
    const year = doc + index;
    return {
      label: year,
      value: year
    };
  });
};
function getStartDateAndEndDateForTreeSelect(dateArray) {
  let startDate = Infinity;
  let endDate = -Infinity;
  var monthNames = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ];

  dateArray.forEach((dateString) => {
    const [year, month] = dateString.split('-');
    const monthIndex = monthNames.indexOf(month);
    const dateValue = new Date(year, monthIndex).getTime();
    if (dateValue < startDate) {
      startDate = dateValue;
    }
    if (dateValue > endDate) {
      endDate = dateValue;
    }
  });

  startDate = new Date(startDate);
  endDate = new Date(endDate);
  endDate.setMonth(endDate.getMonth() + 1);
  endDate.setDate(0);
  return { startDate: formatDateToYYYYMMDD(startDate), endDate: formatDateToYYYYMMDD(endDate) };
}

function formatDateToYYYYMMDD(dateString) {
  const dateObj = new Date(dateString);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
const getFormattedDate = (key) => {
  const date = new Date(key);
  const dateStr = `${m[date.getMonth() + 1]} ${date.getFullYear()}`;
  return dateStr.charAt(0).toUpperCase() + dateStr.slice(1);
};

const getRangeDateSet = (from, to) => {
  const dateSet = [];
  const fromDate = new Date(from);
  const toDate = new Date(to);
  while (getFormattedDate(fromDate) !== getFormattedDate(toDate)) {
    const updatedDate = new Date(fromDate.setMonth(fromDate.getMonth() + 1));
    dateSet.push(getFormattedDate(updatedDate));
  }
  dateSet.unshift(getFormattedDate(from));
  return dateSet;
};

const getDateRanges = (fromDate, lastMonths, futureMonths) => {
  const currentMonth = moment(fromDate);
  const lastMonthsStart = currentMonth
    .clone()
    .subtract(lastMonths, 'months')
    .startOf('month')
    .format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
  const futureMonthsEnd = currentMonth
    .clone()
    .add(futureMonths, 'months')
    .endOf('month')
    .format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');

  return {
    lastMonths: lastMonthsStart,
    futureMonths: futureMonthsEnd
  };
};

function generateMonthYearArray(input1, input2) {
  const formatedInput1 = moment(input1, "MMM 'YY").format("MMM 'YYYY");
  const formatedInput2 = moment(input2, "MMM 'YY").format("MMM 'YYYY");

  const year1 = new Date(formatedInput1).getFullYear();
  const year2 = new Date(formatedInput2).getFullYear();

  const numericMonth1 = new Date(formatedInput1).getMonth() + 1;
  const numericMonth2 = new Date(formatedInput2).getMonth() + 1;

  const yearDifference = parseInt(year2) - parseInt(year1);
  const monthDifference = numericMonth2 - numericMonth1 + yearDifference * 12;

  const resultArray = [];

  for (let i = 0; i <= monthDifference; i++) {
    const currentMonthIndex = (numericMonth1 - 1 + i) % 12;
    const currentYear = parseInt(year1) + Math.floor((numericMonth1 - 1 + i) / 12);
    const currentMonth = monthShort[currentMonthIndex];
    resultArray.push(`${currentMonth} '${currentYear.toString().slice(-2)}`);
  }

  return resultArray;
}

const dateRangeConverter = (inputDateStr) => {
  const formattedDate = moment(inputDateStr).format("MMM 'YY");
  return formattedDate;
};

const monthFull = m;

function generateMonthsInRange({ startDate, endDate }) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const result = {};

  while (start <= end) {
    const year = start.getFullYear();
    const month = start.toLocaleString('default', { month: 'long' }).toLowerCase();
    const key = `${year}-${month}`;

    result[key] = {
      checked: true,
      partialChecked: false
    };

    start.setMonth(start.getMonth() + 1);
  }

  return result;
}

const getColumns = (array) => {
  let columns = [];
  if (Array.isArray(array)) {
    columns = array.map((doc) => {
      const date = new Date(doc);
      const field = `${date.getFullYear()}-${monthFull[date.getMonth() + 1]}`;
      const label = moment(date).format('MMM YYYY');
      return {
        field,
        label
      };
    });
  }
  return columns;
};

const getDateRange = (n = 9) => {
  const toDate = new Date();
  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - n);
  fromDate.setDate(1); // Set the day to the 1st day of the month
  toDate.setMonth(toDate.getMonth() - 1);
  return [fromDate, toDate];
};

const getYearFirstAndLastDate = (givenYear) => {
  const yearFirstDate = moment().year(givenYear).startOf('year').toDate();
  const yearLastDate = moment().year(givenYear).endOf('year').toDate();
  return [yearFirstDate, yearLastDate];
};

function formatDateForMonthSelector(inputDate) {
  const parsedDate = new Date(inputDate);

  const month = parsedDate.toLocaleString('en-US', { month: 'long' });
  const year = parsedDate.getFullYear();

  const formattedDate = `${month} ${year}`;

  return formattedDate;
}

const capsMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
const getYearStartToPreviousMonthEnd = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const startDate = new Date(currentYear, 0, 1);
  const endDate = new Date(currentYear, currentMonth, 0);
  return [startDate, endDate];
};

function formatMonthIntoShort(inputDate) {
  const date = new Date(inputDate);

  const month = minMonthShort[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${year}`;
}

function removeCurrentMonth(listData = []) {
  // Create a deep copy of the listData object
  const deepCopyListData = JSON.parse(JSON.stringify(listData));

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  var monthNames = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ];
  const month = monthNames[currentDate.getMonth()];
  const currentMonthYear = `${year}-${month}`;

  deepCopyListData?.rows?.forEach((ele) => {
    if (ele.hasOwnProperty(currentMonthYear)) {
      delete ele[currentMonthYear];
    }
  });

  return deepCopyListData;
}

const getLastThreeMonths = (year) => {
  const currentDate = new Date();
  const currentYear = year || currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const startMonth = currentMonth - 4;
  const startDate = new Date(currentYear, startMonth, 1);
  const endMonth = currentMonth;
  const endDate = new Date(currentYear, endMonth, 0);
  return [startDate, endDate];
};

const getRequiredStartToEndMonth = (monthRange) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const startMonth = currentMonth - monthRange;
  const startDate = new Date(currentYear, startMonth, 1);
  const endMonth = currentMonth;
  const endDate = new Date(currentYear, endMonth, 0);
  return [startDate, endDate];
};

const getPreviousYearDates = (prevYear) => {
  const fromYear = moment().subtract(prevYear, 'years');

  return {
    'from-date': fromYear.startOf('year').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'),
    'to-date': fromYear.endOf('year').format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
  };
};

function getRequiredMonthLabel(year, month) {
  const currentDate = moment();
  const lastMonths = [];
  for (let i = 1; i <= month; i++) {
    const previousMonth = moment(`${year}-${currentDate.month() + 1}-01`).subtract(i, 'months');
    lastMonths.push(previousMonth.format('MMM YYYY'));
  }
  return lastMonths.reverse();
}

function getMonthLabelsBetweenDates(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  const diffMonths = end.diff(start, 'months');
  const monthLabels = Array.from({ length: diffMonths + 1 }, (_, index) =>
    start.clone().add(index, 'months').format('MMM YYYY')
  );
  return monthLabels;
}

function getMonthDifference(startDate, endDate) {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  const monthDifference = endMoment.diff(startMoment, 'months');
  return monthDifference + 1;
}

function getLastMonthDates() {
  const today = new Date();
  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

  return [lastMonthStart, lastMonthEnd];
}

function convertToYYYYMMDD(dateString) {
  const inputDate = new Date(dateString);

  const year = inputDate.getFullYear();
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  const day = inputDate.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function getCyclelabel(fromDate) {
  const currentDate = moment(fromDate);
  const secondCycleEndDate = currentDate.clone().startOf('month');
  const secondCycleStartDate = secondCycleEndDate.clone().subtract(2, 'months').startOf('month');
  const firstCycleEndDate = secondCycleStartDate.clone().subtract(1, 'months').endOf('month');
  const firstCycleStartDate = firstCycleEndDate.clone().subtract(2, 'months').startOf('month');
  const format = 'MMM YYYY';
  const firstCycleLabel = `${firstCycleStartDate.format(format)} to ${firstCycleEndDate.format(
    format
  )}`;
  const secondCycleLabel = `${secondCycleStartDate.format(format)} to ${secondCycleEndDate.format(
    format
  )}`;

  return {
    firstCycle: firstCycleLabel,
    secondCycle: secondCycleLabel
  };
}

function getTableLabels(fromDate, toDate) {
  const formattedStartDate = moment(fromDate).format('YYYY-MM-DD');
  const formattedeEndDate = moment(toDate).format('YYYY-MM-DD');
  const startMonth = moment.utc(formattedStartDate).startOf('month');
  const endMonth = moment.utc(formattedeEndDate).startOf('month');

  return Array.from({ length: endMonth.diff(startMonth, 'months') + 1 }, (_, index) =>
    startMonth.clone().add(index, 'months').format('MMM YYYY')
  );
}

export {
  getPreviousMonth,
  getRequiredPreviousMonthDates,
  getAdditionalMonth,
  getDynamicAdditionalMonth,
  formattedDates,
  formattedIndexArr,
  getLocalSet,
  monthShort,
  minMonthShort,
  getFromDateAndToDate,
  getDateByType,
  formatDateString,
  getInitialMonthFilter,
  getTillDateRange,
  getTillDateRangeIsoFormat,
  CurrentYearTillDateRange,
  getCurrentMonth,
  endOfMonth,
  convertYearDataArray,
  getLatestMonth,
  basedOnlatestMonth,
  convertYearDataArrayForDashBoard,
  getPreviousMonthDateRange,
  getSubtractedMonthFromCurr,
  getReqMonthsFromCurr,
  getSubtractedMonthLabelFromCurr,
  getRequiredFromDateObj,
  getRequiredFromDateArr,
  getStartAndEndDate,
  getYearList,
  getCurrentYear,
  getInitialMonthFilterForDashBoard,
  getStartDateAndEndDateForTreeSelect,
  formatDate,
  getRangeDateSet,
  getDateRanges,
  getLastSixMonthDateRange,
  getLast3MonthsDateRange,
  generateMonthYearArray,
  dateRangeConverter,
  monthFull,
  monthMapping,
  monthYearRegex,
  generateMonthMapping,
  generateMonthsInRange,
  getRequiredDateRange,
  getLast4RequiredDateRange,
  getColumns,
  getDateRange,
  getYearFirstAndLastDate,
  formatDateForMonthSelector,
  capsMonths,
  getYearStartToPreviousMonthEnd,
  getMonthYearArray,
  formatMonthIntoShort,
  removeCurrentMonth,
  getLastThreeMonths,
  getRequiredStartToEndMonth,
  getPreviousYearDates,
  getTillRequiredDateRange,
  getRequiredMonthLabel,
  getMonthLabelsBetweenDates,
  getRequiredIsoFormat,
  getMonthDifference,
  getLastMonthDates,
  convertToYYYYMMDD,
  getCyclelabel,
  getTableLabels
};
