/* eslint no-console: "off" */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import endPoint from '../constant/endPoints';
import localConstant from '../constant/localConstant';
import { clearLocal, getLocalStroageData, setLocalStroage } from '../helpers/LocalHelper';

const service = {};
const mutex = new Mutex();

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  // baseUrl: 'http://49.249.168.234:8080',
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem(localConstant.TOKEN);
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

service.refresh = () => {
  const url = `${endPoint.refreshToken}`;
  const refresh = getLocalStroageData(localConstant.REFRESH);
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${refresh}`
      },
      body: null
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.status_code === 401) {
          reject(new Error(response?.message));
        }
        resolve(response);
      })
      .catch(() => reject(new Error('Unauthorized')));
  });
};

service.logout = () => {
  clearLocal();
  window.location.href = '/';
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let query = args;
  const state = api.getState();
  if (query && typeof query === 'string' && query.endsWith('amortization=')) {
    query += state.base.amort;
  }
  let result = await baseQuery(query, api, extraOptions);

  if (result.error && result?.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await service.refresh();
        if (refreshResult.data) {
          const { token, refresh_token: refreshToken } = refreshResult.data;
          setLocalStroage(localConstant.TOKEN, token);
          setLocalStroage(localConstant.REFRESH, refreshToken);
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          service.logout();
        }
      } catch {
        service.logout();
      } finally {
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */

const api = createApi({
  reducerPath: 'cubeAuthApi',
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({})
});

export default api;
