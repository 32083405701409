import api from '../../app/api';
import endPoint from '../../constant/endPoints';
import routerPath from '../../constant/routerPath';
import { getDateByType } from '../../helpers/DateHelper';

// query

const dashQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    getMonthData: builder.query({
      query: () => `${endPoint.monthData}`
    }),
    getDashTableData: builder.query({
      query: (arg) =>
        `${endPoint.dashTable}?from-date=${arg.startDate}&to-date=${arg.endDate}&link=${routerPath.dashboard_path}&label=Dashboard&is_recent_search=false&amortization=`
    }),
    getDashChartData: builder.query({
      query: (arg) =>
        `${endPoint.dashChart}?from-date=${arg.date.startDate}&to-date=${arg.date.endDate}&groupby=${arg.group}&amortization=`
    }),
    getTrendData: builder.query({
      query: () => `${endPoint.dashTrend}?amortization=`
    }),
    getOffcanvasTableData: builder.query({
      query: (arg) =>
        `${endPoint.offCanvasTreeTable}?from-date=${arg.fromDt}&to-date=${arg.toDt}&meter-category=${arg.meterCategory}&amortization=`
    }),
    getOffcanvasTableDataForAws: builder.query({
      query: (arg) =>
        `${endPoint.offCanvasTreeTableForAws}?from-date=${arg.fromDt}&to-date=${arg.toDt}&product-code=${arg.productCode}&amortization=`
    }),
    SearchHistory: builder.query({
      query: (arg) => {
        let url = `${endPoint.searchHistory}`;
        if (arg && arg.value) {
          url += arg.value;
        }
        return {
          url,
          method: 'GET'
        };
      },
      invalidatesTags: ['Searchlist']
    }),
    getViewInfo: builder.query({
      query: (extraParams = {}) => {
        let url = endPoint.viewInfo;
        const previousMonth = getDateByType('custom-range');
        const params = {
          'from-date': extraParams.startDate || previousMonth.startDate,
          'to-date': extraParams.endDate || previousMonth.endDate,
          ...extraParams
        };
        url += `?${new URLSearchParams(params).toString()}`;
        return url;
      },
      transformResponse: (resp) => {
        return resp && resp.data ? resp.data : {};
      }
    }),
    getClearCache: builder.query({
      query: () => ({
        url: `${endPoint.clearCache}`
      })
    }),
    getHealthCheck: builder.query({
      query: () => ({
        url: `${endPoint.healthCheck}`
      }),
      transformResponse: (resp) => {
        return resp.data && resp.data ? resp.data : [];
      }
    }),
    getDatabaseData: builder.query({
      query: (params) => {
        let url = `${endPoint.dbSwitchOver}`;
        if (params && Object.keys(params).length > 0) {
          url += `?${new URLSearchParams(params).toString()}`;
        }
        return url;
      },
      transformResponse: (resp) => {
        return resp.data && resp.data ? resp.data : [];
      }
    }),
    getNotifications: builder.query({
      query: () => ({
        url: `${endPoint.notifications}`
      }),
      transformResponse: (resp) => {
        return resp.data && resp.data ? resp.data : [];
      }
    }),
    updateReadNotification: builder.mutation({
      query: (id) => {
        const payload = {
          notification_id: id,
          is_read: true
        };
        return {
          url: `${endPoint.notifications}`,
          method: 'PUT',
          body: payload
        };
      }
    })
  })
});

export const {
  useGetMonthDataQuery,
  useGetDashTableDataQuery,
  useLazyGetDashChartDataQuery,
  useGetTrendDataQuery,
  useLazyGetClearCacheQuery,
  useLazyGetHealthCheckQuery,
  useLazyGetDatabaseDataQuery,
  useLazyGetNotificationsQuery,
  useGetOffcanvasTableDataQuery,
  useGetOffcanvasTableDataForAwsQuery,
  useLazyGetDashTableDataQuery,
  useSearchHistoryQuery,
  useLazySearchHistoryQuery,
  useLazyGetViewInfoQuery,
  useUpdateReadNotificationMutation
} = dashQuery;
