import CubeSpinner from './cubeSpinner';

function Spinner() {
  return (
    <div className="flex w-full justify-center items-center min-h-screen">
      <CubeSpinner />
    </div>
  );
}

export default Spinner;
