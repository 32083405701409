import { getLocalStroageData } from '../helpers/LocalHelper';
import localConstant from './localConstant';

// eslint-disable-next-line no-unused-vars
const config = {
  azure: 'http://localhost:9090/finops',
  aws: 'http://localhost:9091/finops',
  oracle: 'http://localhost:9092/finops'
};

const cloudPartner = getLocalStroageData(localConstant.CLOUD_PARTNER) ?? 'cloudProvider';
const endPoint = {
  login: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/auth/login`,
  monthData: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/dashboard/reporting-months`,
  dashTable: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/dashboard/table`,
  dashChart: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/dashboard/chart-data`,
  offCanvasTreeTable: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/dashboard/meter-category`,
  offCanvasTreeTableForAws: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/dashboard/product-code`,
  dashTrend: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/dashboard/resource-cost-trend`,
  viewList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/views`,
  dynamicViewData: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-view-data`,
  resourceTable: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/cost-analysis/by-resource-group`,
  meterCategory: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/cost-analysis/by-meter-category`,
  getAppViewList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-app-list`,
  getByoAppViewList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-new-app-list`,
  views: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/views`,
  viewColumn: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-columns`,
  tags: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/tag-governance/get-tags`,
  tagsDrill: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/tag-governance/get-tag-data`,
  logout: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/auth/logout`,
  users: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/users`,
  userDetail: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/users/user-detail`,
  meterCategoryLabel: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/cost-analysis/meter-growth/get-meter-categories`,
  growthView: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/cost-analysis/meter-growth/view-data`,
  meterSubCategoryLabel: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/get-meter-sub-categories`,
  anomalies: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/anomalies`,
  appViewList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/app-views`,
  byoAppViewList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/new-app-views`,
  getJobStatus: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-job-status`,
  appViewSubscription: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/get-subscription-name `,
  appViewRG: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/${
    cloudPartner === `oracle` ? `get-product-service` : `get-resource-groups`
  }`,
  appViewMeterCategory: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/${
    (cloudPartner === `aws` && `cost-analysis/growth-view/get-products`) ||
    (cloudPartner === `oracle` && `cost-analysis/growth-view/get-product-service`) ||
    (cloudPartner === `azure` && `cost-analysis/meter-growth/get-meter-categories`)
  }`,
  appViewRN: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/${
    cloudPartner === `oracle` ? `get-product-service` : `get-resource-names`
  }`,
  alerts: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/alerts`,
  currentCost: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/alerts/periodic-total-cost`,
  getApplicationNames: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/alerts/get-all-application-names`,
  csvForGrowthView: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/cost-analysis/meter-growth/view-data-export`,
  csvForDynamicView: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-view-data-export`,
  csvForTagView: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/tag-governance/get-tag-data-export`,
  getAppTreeView: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-app-views`,
  getByoAppTreeView: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-new-app-views`,
  userGroups: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/user-groups`,
  userGroupsList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/user-groups/users`,
  nongroupuser: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/user-groups/non-group-users`,
  alertUsers: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/alerts/get-users-list`,
  searchHistory: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/dashboard/search-history`,
  postAppGroup: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/app-group`,
  appGroupView: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/app-group/app-group-data`,
  viewInfo: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/entity-info/get-view-information`,
  clearCache: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/settings/clear-user-cache`,
  postConfigFilter: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/filter`,
  simulatorFilter: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/forecast/simulator`,
  getFilterOperator: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-filter-condition`,
  getFilterColumn: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-columns`,
  getFilterValue: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-column-values`,
  sendShareData: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/sharing/share`,
  sendScheduleData: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/sharing/schedule`,
  rolesAndPermissionList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/roles/permissions`,
  roleList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/roles`,
  healthCheck: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/health-status/health-overview`,
  permission: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/get-user-permissions`,
  recommendation: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/recommendation/table-data`,
  recommendationPlan: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/recommendation/service-plan`,
  recommendationInvest: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/recommendation/get-capex-investment`,
  planDuration: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/recommendation/get-plan-duration`,
  recommendationObservation: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/recommendation/get-observation`,
  recommendationOptimalTimer: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/recommendation/get-optimal-tier`,
  anomalyDetection: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/forecast/anomaly`,
  notifications: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/notifications`,
  anomalyDetectionDrill: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/forecast/anomaly-data`,
  resourceForecastList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/forecast/resource`,
  pricingModelList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/forecast/pricing-model`,
  meterCtryList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/forecast/meter-category`,
  meterSubCtryList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/forecast/meter-sub-category`,
  resourceAndCostList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/forecast/get-simulator-data`,
  trendAnalysis: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/forecast/resource-trend`,
  appList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-app-cost`,
  byoAppList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-new-app-cost`,
  capabilityList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/capability-view`,
  getTagAudit: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/tag-governance/get-tag-audit`,
  appCardData: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/common/card-data`,
  getDataImportedDate: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/common/date-range`,
  capabilities: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/capabilities`,
  capabilitiesUpload: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/capabilities`,
  updateColumnView: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/custom-app-views`,
  tagAuditFiltered: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/tag-governance/filtered-tags`,
  dbSwitchOver: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/list-database`,
  pricingModelData: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/dashboard/pricing-model-data`,
  orgOverView: `${process.env.REACT_APP_BASE_URL}-backend/public/api/common/org-overview`,
  SpecificMonthCost: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/common/cost-data`,
  getBusinessUnitList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/dashboard/business-unit`,
  getMajorAnomalyList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/dashboard/anomaly`,
  businessApp: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/business-view-apps`,
  getTagMaturity: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/dashboard/tag`,
  getRoiData: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/roi/roi-data`,
  getVoyaSolutionViewData: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-demo-view-data`,
  getDelectedUserData: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/users/user-data`,
  patternViewList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/by-pattern`,
  getAppTreePatternView: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-pattern-app-views`,
  getAppViewPatternList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-pattern-app-list`,
  patternAppCostList: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/build-your-own/get-pattern-app-cost`,
  refreshToken: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/auth/refresh`,
  customizedFilter: `${process.env.REACT_APP_BASE_URL}${
    cloudPartner === `aws` || cloudPartner === `oracle` ? `-${cloudPartner}` : `-backend`
  }/public/api/dashboard/customizedfilter`
};

export default endPoint;
