const getLocalStroageData = (key) => {
  return localStorage.getItem(key);
};

const setLocalStroage = (key, payload) => {
  const data = typeof payload === 'string' ? payload : JSON.stringify(payload);
  localStorage.setItem(key, data);
};

const clearLocal = () => {
  localStorage.clear();
};

export { getLocalStroageData, setLocalStroage, clearLocal };
