/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { FiSend } from 'react-icons/fi';
import { Dialog } from 'primereact/dialog';
import { TbArrowsDiagonal, TbArrowsDiagonalMinimize2 } from 'react-icons/tb';
import botImg from '../../assets/icons/botImg.svg';
import { handleBotHistory } from '../base/baseSlice';
import { dotWaveAnimation } from '../../components/common/dotAnimation';

function CloudAi({ handleCloseChat }) {
  const dispatch = useDispatch();
  const textAreaRef = useRef(null);
  const messageEndRef = useRef(null);
  const [copyTimer, setCopyTimer] = useState(null);
  const [chatMessage, setChatMessage] = useState('');
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [isMaximized, setIsMaximized] = useState(false);
  const amort = useSelector((state) => state.base.amort);
  const botHistory = useSelector((state) => state.base.chatWindowHistory);

  const handleGetDetails = (event) => setChatMessage(event.target.value);

  const handleMaximize = () => setIsMaximized((prev) => !prev);

  const extractNumericValues = (data) =>
    _.chain(data)
      .map((obj) => _.find(_.values(obj), _.isString))
      .compact()
      .join('\n')
      .value();

  const triggerChat = async (message) => {
    if (!message) return;

    if (textAreaRef.current) textAreaRef.current.disabled = true;

    const userMessage = { role: 'user', parts: [{ text: message }] };
    const typingMessage = { role: 'model', parts: [{ text: 'typing' }] };

    const updatedMessages = [...botHistory, userMessage, typingMessage];
    dispatch(handleBotHistory(updatedMessages));
    setChatMessage('');
    const url = `https://122.165.79.72:5002/query`;
    // const url = `http://127.0.0.1:5000/query`;
    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: message, amortization: amort })
      });

      const resjson = await res.json();
      const responseMessage = resjson?.response;
      const formatResponse =
        typeof responseMessage === 'object' && responseMessage?.length
          ? extractNumericValues(responseMessage)
          : responseMessage;
      const addNewLine = formatResponse && typeof responseMessage === 'object';

      const finalMessages = [
        ...updatedMessages.slice(0, -1),
        {
          // Remove the typing message
          role: 'model',
          parts: [{ text: formatResponse }],
          hasQuery: typeof responseMessage === 'object' && responseMessage?.length,
          isQueryShown: false,
          query: formatResponse && typeof responseMessage === 'object' ? resjson.query : '',
          addNewLine
        }
      ];

      dispatch(handleBotHistory(finalMessages));
    } catch (error) {
      console.error('Error:', error);
      const finalMessages = [
        ...updatedMessages.slice(0, -1),
        {
          // Remove the typing message
          role: 'model',
          parts: [{ text: 'Failed to get response. Please try again.' }]
        }
      ];

      dispatch(handleBotHistory(finalMessages));
    } finally {
      if (textAreaRef.current) {
        textAreaRef.current.disabled = false;
        textAreaRef.current.focus();
      }
    }
  };

  const getMarginClass = (index, length) => {
    if (index === 0) return 'mb-2.5';
    if (index === length - 1) return 'mt-2.5';
    return 'my-2.5';
  };

  const handleKeyEvent = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      triggerChat(chatMessage);
    }
  };

  const copySelectedQuery = async (query, index) => {
    try {
      await navigator.clipboard.writeText(query);
      setCopiedIndex(index);

      if (copyTimer) {
        clearTimeout(copyTimer);
      }

      const newTimer = setTimeout(() => {
        setCopiedIndex(null);
      }, 3000);

      setCopyTimer(newTimer);
    } catch (err) {
      console.error('Failed to copy query: ', err);
    }
  };

  const displayQueryState = (selectedQueryIndex, isShown) => {
    if (!isShown) {
      const updatedMessages = botHistory.map((msg, index) =>
        index === selectedQueryIndex ? { ...msg, isQueryShown: true } : msg
      );
      dispatch(handleBotHistory(updatedMessages));
    }
  };

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const focusTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [chatMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [botHistory]);

  useEffect(() => {
    setTimeout(scrollToBottom, 0);
    setTimeout(focusTextArea, 0);
  }, []);

  return (
    <main>
      <Dialog
        visible
        modal={false}
        draggable={false}
        resizable={false}
        style={{
          bottom: '-12%',
          height: '64.5vh',
          width: '22.031vw',
          right: '-37.1%',
          borderRadius: '15px',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 14px 2px',
        }}
        maximized={isMaximized}
        onHide={handleCloseChat}
        onMaximize={handleMaximize}
        className={`${isMaximized ? 'maximal_ai' : 'minimal_ai'}`}
        icons={() => (
          <button className="p-dialog-header-icon p-link" onClick={handleMaximize}>
            {isMaximized ? (
              <TbArrowsDiagonalMinimize2 className="w-5 h-5 font-medium !text-white" />
            ) : (
              <TbArrowsDiagonal className="w-5 h-5 font-medium !text-white" />
            )}
          </button>
        )}
        header={
          <p className="flex justify-center text-[18px] pl-16 !font-normal text-white">
            CloudLens.ai
          </p>
        }>
        <section
          className={`calc-botsection ${isMaximized ? 'px-20' : 'px-5'} overflow-y-auto py-5`}>
          {botHistory?.length > 0 &&
            botHistory.map((msg, chatIndex) => (
              <div
                key={`chat-${chatIndex}`}
                className={`w-full flex gap-2 ${
                  msg.role === 'user' ? 'justify-end' : 'justify-start'
                }`}>
                {msg.role === 'model' && (
                  <div
                    className={`flex justify-start items-end ${getMarginClass(
                      chatIndex,
                      botHistory.length
                    )}`}>
                    <img src={botImg} className="!w-8 !h-8" />
                  </div>
                )}
                <div
                  className={`w-auto max-w-4/5 ${getMarginClass(
                    chatIndex,
                    botHistory.length
                  )} p-3 flex-col rounded-[10px] ${
                    msg.role === 'user'
                      ? 'bg-[#297CC5]'
                      : msg.parts[0].text === 'typing'
                      ? 'bg-transparent'
                      : 'bg-[#e6f4f1]'
                  }`}>
                  <div
                    className={`${msg.role === 'user' ? 'text-white' : 'text-black'} text-[15px]`}>
                    <div className="flex items-center justify-between">
                      {msg.parts[0].text === 'typing' ? (
                        dotWaveAnimation()
                      ) : (
                        <p className={`${msg.hasQuery && 'font-bold'}`}>{msg.parts[0].text}</p>
                      )}
                      {msg.hasQuery &&
                        msg.isQueryShown &&
                        (copiedIndex === chatIndex ? (
                          <i className="pi pi-check !w-5 !h-5 !text-green-700" />
                        ) : (
                          <i
                            onClick={() => copySelectedQuery(msg.query, chatIndex)}
                            className="pi pi-copy !w-5 !h-5 !text-gray-700 cursor-pointer"
                          />
                        ))}
                    </div>
                    {msg.addNewLine && <br className="select-none" />}
                    {msg.role === 'model' && msg.hasQuery && (
                      <span>
                        <label
                          onClick={() => displayQueryState(chatIndex, msg.isQueryShown)}
                          className={`select-none text-[#297CC5] font-bold cursor-pointer ${
                            !msg.isQueryShown && 'underline'
                          }`}>
                          {!msg.isQueryShown ? 'Do you need a query ?' : 'Query =>'}{' '}
                        </label>
                        {msg.isQueryShown && msg.query}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          <div ref={messageEndRef} />
        </section>

        <footer className={`${isMaximized ? 'px-20' : 'px-5'} w-full h-[60px] bg-[#F2F2F2]`}>
          <div
            className={`bg-[#F2F2F2] rounded-full flex justify-between items-center gap-2 h-[60px]`}>
            <textarea
              ref={textAreaRef}
              className={`w-90perc !h-[45px] py-2 rounded-md resize-none overflow-auto bg-[#F2F2F2]`}
              placeholder="Type a reply..."
              value={chatMessage}
              onChange={handleGetDetails}
              rows={1}
              style={{ maxHeight: '200px' }}
              onKeyDown={handleKeyEvent}
            />
            <div
              aria-hidden
              className="w-10 h-10 flex items-center justify-center cursor-pointer bg-[#D7D7D7] border rounded-full"
              onClick={() => triggerChat(chatMessage)}>
              <FiSend className="w-6 h-6 text-gray-600" />
            </div>
          </div>
        </footer>
      </Dialog>
    </main>
  );
}

export default CloudAi;
